import React from "react";
import styled from "styled-components";

function Locations() {
    return (
        <>
            <MainContainer id="locations" name="locations">
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <ContentBox>
                            <MainHeading>
                                <span> Our</span> Locations
                            </MainHeading>
                            <Para>
                                We have positioned ourselves with multiple locations to serve our
                                valued customers. Our branches are spread across the India & Middle
                                East, ensuring easy access to our high-quality products and
                                services.
                            </Para>
                        </ContentBox>
                        <LocationBox>
                            <LocationCard>
                                <LocationImgBox>
                                    <LocationImg src={require("../assets/images/location.png")} />
                                </LocationImgBox>
                                <LocationTitle>
                                    <Title>Everest Bakers</Title>
                                    <TitleImage>
                                        <TitleImg
                                            src={require("../assets/images/arrow.svg").default}
                                        />
                                    </TitleImage>
                                </LocationTitle>
                                <LocationName>
                                    <LocationMapIcon>
                                        <LocationMapImg
                                            src={require("../assets/images/map.svg").default}
                                        />
                                    </LocationMapIcon>
                                    <MapHeading>Kollam, Kerala</MapHeading>
                                </LocationName>
                                <LocationNumber>
                                    <LocationMapIcon>
                                        <LocationMapImg
                                            src={require("../assets/images/call.svg").default}
                                        />
                                    </LocationMapIcon>
                                    <Number>+91 9895659915</Number>
                                </LocationNumber>
                            </LocationCard>
                            <LocationCard>
                                <LocationImgBox>
                                    <LocationImg src={require("../assets/images/location2.png")} />
                                </LocationImgBox>
                                <LocationTitle>
                                    <Title>Everest Roastery LLC</Title>
                                    <TitleImage>
                                        <TitleImg
                                            src={require("../assets/images/arrow.svg").default}
                                        />
                                    </TitleImage>
                                </LocationTitle>
                                <LocationName>
                                    <LocationMapIcon>
                                        <LocationMapImg
                                            src={require("../assets/images/map.svg").default}
                                        />
                                    </LocationMapIcon>
                                    <MapHeading>Ajman, U.A.E</MapHeading>
                                </LocationName>
                                <LocationNumber>
                                    <LocationMapIcon>
                                        <LocationMapImg
                                            src={require("../assets/images/call.svg").default}
                                        />
                                    </LocationMapIcon>
                                    <Number>+971 0582558380</Number>
                                </LocationNumber>
                            </LocationCard>
                        </LocationBox>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Locations;

const MainContainer = styled.div`
    padding: 120px 0px;
    background-color: #fafafa;
    @media all and (max-width: 980px) {
        padding: 70px 0px;
    }
    @media all and (max-width: 980px) {
        padding: 50px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div``;
const ContentBox = styled.div``;
const MainHeading = styled.div`
    font-family: "poppins_semibold";
    font-size: 36px;
    margin-bottom: 25px;
    text-align: center;
    span {
        color: #d0741d;
    }
    @media all and (max-width: 360px) {
        font-size: 32px;
    }
`;
const Para = styled.p`
    width: 85%;
    margin: 0 auto;
    text-align: center;
    font-size: 19px;
    margin-bottom: 40px;
    @media all and (max-width: 640px) {
        width: 100%;
        font-size: 17px;
    }
`;
const LocationBox = styled.div`
    display: flex;
    justify-content: space-evenly;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const LocationCard = styled.div`
    width: 31%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media all and (max-width: 1080px) {
        width: 35%;
    }
    @media all and (max-width: 980px) {
        width: 41%;
    }
    @media all and (max-width: 768px) {
        width: 47%;
    }
    @media all and (max-width: 640px) {
        width: 80%;
        margin-bottom: 50px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const LocationImgBox = styled.div`
    width: 100%;
`;
const LocationImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 8px;
`;
const LocationName = styled.h2`
    font-size: 17px;
    font-family: "poppins_medium";
    margin-top: 15px;
    display: flex;
    align-items: center;
`;

const LocationNumber = styled.div`
    font-family: "poppins_medium";
    margin-top: 15px;
    display: flex;
`;

const LocationTitle = styled.a`
    display: flex;
    cursor: pointer;
    margin-top: 20px;
`;

const LocationMapIcon = styled.div`
    width: 20px;
    margin-right: 8px;
`;

const MapHeading = styled.p`
    font-size: 17px;
    color: #191f33;
`;

const LocationMapImg = styled.img`
    width: 20px;
`;
const Number = styled.p`
    color: #191f33;
`;

const TitleImage = styled.div`
    width: 15px;
`;
const TitleImg = styled.img`
    width: 100%;
    display: block;
`;
const Title = styled.h2`
    font-size: 20px;
    font-family: "poppins_medium";
    cursor: pointer;
    margin-right: 10px;
`;
