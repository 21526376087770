import React, { useEffect } from "react";
// Packages
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
//Images
//Components
import bgimage from "../assets/images/bgimage.png";

function Spotlight() {
    useEffect(() => {
        // Initialize AOS
        AOS.init();

        // Refresh AOS when the component updates
        AOS.refresh();
    }, []);
    return (
        <>
            <MainContainer id='home'>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <LeftContainer data-aos="slide-right" data-aos-duration="1000">
                            <MainHeading>
                                Experience the
                                <span> Magic</span> of Freshly Goodness
                            </MainHeading>
                            {/* <Para>
                                We help you access finest services like course search, job search,
                                accommodation search, pick and drop requests, visa enquiries all
                                around the UK
                            </Para> */}
                        </LeftContainer>
                        <RightContainer data-aos="slide-left" data-aos-duration="1000">
                            <ImageBox>
                                <Image
                                    src={require("../assets/images/spotlight-image.png")}
                                    alt="spotlight"
                                />
                            </ImageBox>
                        </RightContainer>
                    </SubContainer>
                    {/* <ServiceContainer>
                            <MainDiv>
                                <ServiceBox>
                                    <ServiceImg
                                        src={require("../assets/images/service-2.svg").default}
                                    />
                                </ServiceBox>
                                <SubHeading>No Preservatives & Added Colors</SubHeading>
                            </MainDiv>
                            <MainDiv>
                                <ServiceBox>
                                    <ServiceImg
                                        src={require("../assets/images/service-1.svg").default}
                                    />
                                </ServiceBox>
                                <SubHeading>100% Hygiene Guaranteed</SubHeading>
                            </MainDiv>
                            <MainDiv>
                                <ServiceBox>
                                    <ServiceImg
                                        src={require("../assets/images/service-5.svg").default}
                                    />
                                </ServiceBox>
                                <SubHeading>Available in 100+ Stores</SubHeading>
                            </MainDiv>
                            <MainDiv>
                                <ServiceBox>
                                    <ServiceImg
                                        src={require("../assets/images/service-4.svg").default}
                                    />
                                </ServiceBox>
                                <SubHeading>100% Certified Company</SubHeading>
                            </MainDiv>
                        </ServiceContainer> */}
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Spotlight;

const MainContainer = styled.div`
    padding: 160px 0px 90px 0px;
    background: #fafafa;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: initial;
    background-position-x: left;
    background-position-y: bottom;

    @media all and (max-width: 980px) {
        padding: 120px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const LeftContainer = styled.div`
    width: 46%;
    position: relative;
    @media all and (max-width: 1200px) {
        width: 43%;
    }
    @media all and (max-width: 1080px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        text-align: center;
    }
    @media all and (max-width: 768px) {
        [data-aos] {
            opacity: 1 !important;
            transform: none !important;
            transition: none !important;
            pointer-events: none !important;
        }
    }
`;

const MainHeading = styled.div`
    margin-bottom: 25px;
    font-size: 58px;
    width: 88%;
    font-family: "poppins_semibold";
    position: relative;
    line-height: 80px;
    border-radius: 50%;
    span {
        color: #d0741d;
    }
    @media all and (max-width: 1380px) {
        font-size: 55px;
    }
    @media all and (max-width: 1200px) {
        font-size: 46px;
    }
    @media all and (max-width: 980px) {
        font-size: 42px;
    }
    @media all and (max-width: 768px) {
        font-size: 36px;
        line-height: 60px;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 31px;
        line-height: 50px;
    }
`;
const Para = styled.p`
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const RightContainer = styled.div`
    width: 46%;

    @media all and (max-width: 1081px) {
        width: 67%;
        margin: 0 auto;
    }
    @media all and (max-width: 640px) {
        width: 85%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
    @media all and (max-width: 768px) {
        [data-aos] {
        }
    }
`;
const ImageBox = styled.div`
    width: 74%;
    margin: 0 auto;
`;
const Image = styled.img`
    height: 100%;
    width: 100%;
    display: block;
`;
// const ServiceContainer = styled.ul`
//     margin: 0 auto;
//     background: #ffffff;
//     box-shadow: 13px 4px 64px rgba(0, 0, 0, 0.08);
//     border-radius: 24px;
//     margin-top: 55px;
//     display: flex;
//     padding: 18px;
//     @media all and (max-width: 980px) {
//         padding: 10px;
//     }
//     @media all and (max-width: 768px) {
//         flex-wrap: wrap;
//     }
//     @media all and (max-width: 480px) {
//         background: none;
//         box-shadow: none;
//     }
// `;
// const MainDiv = styled.li`
//     width: 25%;
//     text-align: center;
//     background: #ffffff;

//     @media all and (max-width: 768px) {
//         width: 34%;
//         margin: 20px auto;
//     }

//     @media all and (max-width: 640px) {
//         width: 40%;
//     }
//     @media all and (max-width: 480px) {
//         width: 100%;
//         padding: 20px;
//         border-left: none;
//         background: #ffffff;
//         box-shadow: 13px 4px 64px rgba(0, 0, 0, 0.08);
//         border-radius: 24px;
//         margin: 8px auto;
//         :last-child {
//             margin-bottom: none;
//         }
//     }
// `;

// const SubHeading = styled.h5`
//     font-family: "poppins_medium";
//     color: #1f3347;

//     margin-top: 20px;
// `;
// const ServiceBox = styled.div`
//     width: 20%;
//     margin: 0 auto;
// `;
// const ServiceImg = styled.img`
//     width: 100%;
//     height: 100%;
//     display: block;
// `;
