import React, { useEffect } from "react";
//Packages
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

function About() {
    useEffect(() => {
        AOS.init();

        AOS.refresh();
    }, []);
    return (
        <>
            <MainContainer id="about" name="about">
                <WrapperContainer className="wrapper">
                    <SubContainer data-aos="fade-up" data-aos-duration="1000">
                        <TopContainer>
                            <LeftContainer>
                                <ImageBox>
                                    <AboutImg
                                        src={require("../assets/images/spotlight-about.png")}
                                    />
                                </ImageBox>
                            </LeftContainer>
                            <RightContainer>
                                <Small>About Us</Small>
                                <MainHeading>
                                    Who
                                    <span> We</span> Are
                                </MainHeading>
                                <Para>
                                    We're a haven for all things sweet and savoury. Established with
                                    a deep love for baking and a dedication to quality, our bakery
                                    has been serving our community with irresistible treats for
                                    years
                                </Para>
                            </RightContainer>
                        </TopContainer>
                        <BottomContainer>
                            <AboutCard>
                                <MainList>
                                    <SubList>
                                        <Heading>Mission</Heading>
                                        <CardPara>
                                            Our mission is to bring joy to your taste buds. With a
                                            legacy rooted in products recipes and a commitment to
                                            using only the finest, locally sourced ingredients, we
                                            craft each treat with love and precision
                                        </CardPara>
                                    </SubList>
                                    <SubList>
                                        <Heading>Vision</Heading>
                                        <CardPara>
                                            We aspire to create a world where every bite of our
                                            baked goods brings a smile to your face and a moment of
                                            pure delight. We're committed to upholding our legacy of
                                            quality
                                        </CardPara>
                                    </SubList>
                                </MainList>
                            </AboutCard>
                        </BottomContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default About;

const MainContainer = styled.div`
    padding: 120px 0px;
    background: rgba(247, 242, 232, 0.6);
    @media all and (max-width: 980px) {
        padding: 70px 0px;
    }
`;
const SubContainer = styled.div``;
const WrapperContainer = styled.div``;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;
const LeftContainer = styled.div`
    width: 45%;
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const ImageBox = styled.div`
    width: 100%;
`;
const AboutImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    aspect-ratio: 2/1;
    object-fit: cover;
    border-radius: 5px;
`;
const RightContainer = styled.div`
    width: 48%;
    @media all and (max-width: 1200px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto 50px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Small = styled.h3`
    margin-bottom: 15px;
    @media all and (max-width: 1200px) {
        margin-bottom: 10px;
    }
`;
const MainHeading = styled.div`
    font-family: "poppins_semibold";
    font-size: 36px;
    margin-bottom: 15px;

    span {
        color: #d0741d;
    }
    @media all and (max-width: 1080px) {
        font-size: 28px;
        margin-bottom: 10px;
    }
`;
const Para = styled.p`
    width: 85%;
    font-size: 19px;

    @media all and (max-width: 1200px) {
        width: 100%;
        font-size: 18px;
    }
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
const BottomContainer = styled.div``;
const AboutCard = styled.div``;
const MainList = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const SubList = styled.li`
    padding: 20px;
    background-color: #ffff;
    box-shadow: rgba(0, 0, 0, 0.08) 13px 4px 64px;
    width: 48%;
    border-radius: 20px;
    transition: all 0.3s ease 0s;
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        padding: 15px;
    }
`;
const Heading = styled.h2`
    font-family: "poppins_semibold";
    color: #000;
    text-align: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`;
const CardPara = styled.p`
    font-size: 17px;
    color: #767373;
    min-height: 120px;
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
