import React from "react";
import styled from "styled-components";

function ContactUs() {
    let tag = document.querySelector('div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom div.gmnoprint div')
    console.log('tag', tag);
    return (
        <>
            <MainContainer id="contact" name="contact">
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <LeftContainer>
                            <MainHeading>
                                <span>Get</span> in Touch
                            </MainHeading>
                            <Para>
                                Contact Us for further details regarding our
                                project
                            </Para>
                            <FieldBox>
                                <InputBox>
                                    <InputRow>
                                        <Input type="text" placeholder="Name" />
                                    </InputRow>
                                    <InputRow>
                                        <Input
                                            type="email"
                                            placeholder="Email"
                                        />
                                    </InputRow>
                                    <InputRow>
                                        <Input
                                            type="tel"
                                            maxLength={"10"}
                                            placeholder="Phone Number"
                                        />
                                    </InputRow>
                                    <SubmitButton>Submit</SubmitButton>
                                </InputBox>
                            </FieldBox>
                        </LeftContainer>
                        <RightContainer>
                            <MapContainer>
                                {/* <MapImg src={require("../assets/images/maps.png")} /> */}
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.320959458521!2d76.87302067576198!3d8.565104296076298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bf83c7c5f47f%3A0x75b8169994a21517!2sLogtech%20Infoway%20Pvt%20Ltd.%20Kazhakuttam!5e0!3m2!1sen!2sin!4v1702713987628!5m2!1sen!2sin"
                                    style={{
                                        width: "100%",
                                        height: "450px",
                                        border: "0",
                                    }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </MapContainer>
                        </RightContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default ContactUs;

const MainContainer = styled.div`
    padding: 120px 0px;
    @media all and (max-width: 768px) {
        padding: 70px 0px;
    }
    @media all and (max-width: 360px) {
        padding: 50px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const ContentBox = styled.div``;
const LeftContainer = styled.div`
    width: 48%;
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto 50px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const MainHeading = styled.div`
    font-family: "poppins_semibold";
    font-size: 36px;
    margin-bottom: 25px;
    span {
        color: #d0741d;
    }
    @media all and (max-width: 480px) {
        font-size: 32px;
    }
`;
const RightContainer = styled.div`
    width: 45%;
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const MapContainer = styled.div`
    width: 80%;
    iframe {
        width: 100%;
        height: 450px;
    }
    @media all and (max-width: 1080px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        iframe {
            width: 100%;
            height: 330px !important;
        }

    }
`;
const MapImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const Para = styled.p`
    font-family: "poppins_medium";
    font-size: 18px;
    margin-bottom: 30px;
`;
const InputBox = styled.div``;

const InputRow = styled.div`
    margin-bottom: 30px;
`;
const Input = styled.input`
    border: 1px solid var(--Gray-5, #e0e0e0);
    padding: 15px;
    font-size: 16px;
    width: 100%;
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const FieldBox = styled.div`
    background: #ffffff;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const SubmitButton = styled.button`
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 100%;
    background: #d0741d;
    height: 55px;
    font-family: "poppins_medium";
    transition: all 0.3s ease 0s;
    @media all and (max-width: 480px) {
        height: 45px;
    }
`;
