import React from "react";

//Packages
import styled from "styled-components";

//Slider Packages
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TopProducts() {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <>
            <MainContainer id='products'>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <LeftContainer>
                            <Small>Products</Small>
                            <MainHeading>
                                Top<span> Selling</span> Products
                            </MainHeading>
                            <Para>
                                Our finest selection of,products that have won the hearts of our
                                ustomers.We take pride in offering you an exclusive range of premium
                                items that stand out for their quality
                            </Para>
                            <Button>
                                <span>View All Products</span>
                            </Button>
                        </LeftContainer>
                        <RightContainer>
                            <ImageSlider>
                                <CustomSlider {...sliderSettings}>
                                    <ImageContainer>
                                        <SlideImg src={require("../assets/images/slider-1.png")} />
                                    </ImageContainer>

                                    <ImageContainer>
                                        <SlideImg src={require("../assets/images/slider-2.png")} />
                                    </ImageContainer>

                                    <ImageContainer>
                                        <SlideImg src={require("../assets/images/slider-3.png")} />
                                    </ImageContainer>

                                    <ImageContainer>
                                        <SlideImg src={require("../assets/images/slider-4.png")} />
                                    </ImageContainer>
                                </CustomSlider>
                            </ImageSlider>
                        </RightContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default TopProducts;
const CustomSlider = styled(Slider)`
    .slick-dots li.slick-active button::before {
        color: orange;
        :hover {
            color: orange;
        }
    }
    .slick-dots {
        li {
            button {
                &::before {
                    font-size: 18px;
                }
            }
        }
    }
`;
const ImageSlider = styled.div``;
const MainContainer = styled.div`
    padding: 120px 0px;
    @media all and (max-width: 980px) {
        padding: 70px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
    }
`;
const LeftContainer = styled.div`
    width: 45%;
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto 50px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Small = styled.h4`
    margin-bottom: 15px;
`;
const MainHeading = styled.div`
    width: 60%;
    font-family: "poppins_semibold";
    font-size: 36px;
    margin-bottom: 25px;
    span {
        color: #d0741d;
    }
    @media all and (max-width: 1080px) {
        font-size: 28px;
        width: 80%;
    }
`;
const Para = styled.p`
    width: 85%;
    font-size: 19px;
    margin-bottom: 30px;
    @media all and (max-width: 1080px) {
        font-size: 16px;
        margin-bottom: 10px;
        width: 95%;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 25px;
    }
`;
const Button = styled.button`
    cursor: pointer;
    color: black;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    border: 1px solid #d0741d;
    width: 182px;
    height: 52px;
    font-family: "poppins_medium";
    transition: all 0.3s ease 0s;
`;
const RightContainer = styled.div`
    width: 52%;
    .slick-initialized .slick-slide {
        display: block;
        padding: 0px 10px;
    }
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto 50px;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;

const ImageContainer = styled.div``;
const SlideImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
