import React from "react";
import styled from "styled-components";

function Distributor() {
    const whatsappNumber = "+919207802002";
    const whatsappLink = `https://wa.me/${whatsappNumber}/?text=Hello, I want to join your team`;

    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <LeftBox>
                            <Heading>BECOME A DISTRIBUTOR</Heading>
                        </LeftBox>
                        <RightBox>
                            <ContactButton href={whatsappLink} target="_blank">
                                Contact Us
                            </ContactButton>
                        </RightBox>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Distributor;

const MainContainer = styled.div`
    padding: 100px 0px;
    background-color: #f7f2e8;
    @media all and (max-width: 480px) {
        padding: 70px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 640px) {
        flex-direction: column;
        flex-wrap: wrap;
    }
`;
const LeftBox = styled.div``;
const Heading = styled.h2`
    font-family: "poppins_semibold";
    font-size: 32px;
    @media all and (max-width: 768px) {
        font-size: 28px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 20px;
        text-align: center;
    }
    @media all and (max-width: 640px) {
        font-size: 25px;
    }
    @media all and (max-width: 360px) {
        font-size: 22px;
    }
`;
const RightBox = styled.div``;
const ContactButton = styled.a`
    padding: 15px 35px;
    background: #d0741d;
    border-radius: 5px;
    display: block;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    @media all and (max-width: 768px) {
        font-size: 15px;
        padding: 12px 28px;
    }
    @media all and (max-width: 640px) {
        padding: 10px 28px;
    }
`;
