import React, { useEffect, useState } from "react";

// Packages
import { NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import styled from "styled-components";

function Header() {
    // Hamburger Menu Modal
    const [isMenuModal, setIsMenuModal] = useState(false);

    const location = useLocation();

    // Scroll to top function
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <RightContainer>
                            <h1>
                                <LogoBox to="/">
                                    <Logo
                                        src={
                                            require("../assets/images/main-logo.svg")
                                                .default
                                        }
                                        alt="logo"
                                    />
                                </LogoBox>
                            </h1>
                        </RightContainer>
                        <LeftContainer>
                            <MainList>
                                <HomeNav
                                    to="home"
                                    spy={true}
                                    smooth={"true"}
                                    offset={-70}
                                    duration={500}
                                    activeClass="active"
                                    onClick={() => {
                                        location.pathname !== "/" &&
                                            window.location.assign("/");
                                    }}
                                >
                                    Home
                                    <span></span>
                                </HomeNav>
                                <HomeNav
                                    to="about"
                                    spy={true}
                                    smooth={"true"}
                                    offset={-70}
                                    duration={500}
                                    activeClass="active"
                                    onClick={() => {
                                        location.pathname !== "/" &&
                                            window.location.assign("/");
                                    }}
                                >
                                    About
                                    <span></span>
                                </HomeNav>
                                <HomeNav
                                    to="products"
                                    spy={true}
                                    smooth={"true"}
                                    offset={-70}
                                    duration={500}
                                    activeClass="active"
                                    // to="/products"
                                    // className={location.pathname === "" ? "active" : ""}
                                    onClick={() => {
                                        location.pathname !== "/" &&
                                            window.location.assign("/");
                                    }}
                                >
                                    Products
                                    <span></span>
                                </HomeNav>
                                <HomeNav
                                    to="services"
                                    spy={true}
                                    smooth={"true"}
                                    offset={-70}
                                    duration={500}
                                    activeClass="active"
                                    onClick={() => {
                                        location.pathname !== "/" &&
                                            window.location.assign("/");
                                    }}
                                >
                                    Services
                                    <span></span>
                                </HomeNav>
                                <HomeNav
                                    to="locations"
                                    spy={true}
                                    smooth={"true"}
                                    offset={-70}
                                    duration={500}
                                    activeClass="active"
                                    onClick={() => {
                                        location.pathname !== "/" &&
                                            window.location.assign("/");
                                    }}
                                >
                                    Locations
                                    <span></span>
                                </HomeNav>
                                <HomeNav
                                    to="contact"
                                    spy={true}
                                    smooth={"true"}
                                    offset={-70}
                                    duration={500}
                                    activeClass="active"
                                    onClick={() => {
                                        location.pathname !== "/" &&
                                            window.location.assign("/");
                                    }}
                                >
                                    Contact
                                    <span></span>
                                </HomeNav>
                            </MainList>
                        </LeftContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Header;

const MainContainer = styled.div`
    padding: 13px 0px;
    background: #ffffff;
    position: fixed;
    width: 100%;
    z-index: 2;
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const RightContainer = styled.div`
    width: 9%;
    @media all and (max-width: 980px) {
        width: 10%;
    }
    @media all and (max-width: 640px) {
        width: 16%;
    }
    @media all and (max-width: 768px) {
        width: 14%;
    }
    @media all and (max-width: 640px) {
        width: 18%;
    }
    @media all and (max-width: 480px) {
        width: 22%;
    }
    h1 {
        width: 100%;
        line-height: 65%;
        a {
            display: inline-block;
            width: 100%;
        }
    }
`;
const LogoBox = styled(NavLink)``;
const Logo = styled.img`
    width: 100%;
    display: block;
    height: 100%;
`;
const LeftContainer = styled.div`
    width: 70%;
    @media all and (max-width: 1080px) {
        width: 80%;
    }
    @media all and (max-width: 980px) {
        width: 85%;
    }
    @media all and (max-width: 768px) {
        display: none;
    }
`;
const MainList = styled.nav`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HomeNav = styled(ScrollLink)`
    position: relative;
    span {
        width: 100%;
        height: 0px;
        border-bottom: 2px solid #d0741d;
        position: absolute;
        bottom: 0;
        right: 50%;
        visibility: hidden;
        opacity: 0;
        transition: all 150ms ease-in-out;
    }
    &.active {
        color: #d0741d;
        span {
            opacity: 1;
            visibility: visible;
            right: 0;
        }
    }
`;
