import React from "react";
import styled from "styled-components";
function Services() {
    return (
        <>
            <MainContainer id="services" name="services">
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <ContentBox>
                            <MainHeading>
                                Our <span>Services</span>
                            </MainHeading>
                            <Para>
                                We are dedicated to providing you with exceptional services that
                                cater to your every need. Our mission is to deliver top-notch
                                solutions that enhance your life and business
                            </Para>
                        </ContentBox>
                        <ServiceCard>
                            <ServiceBox>
                                <ServiceLeft>
                                    <AvatarIcon>
                                        <Image
                                            src={require("../assets/images/airplane.svg").default}
                                            alt="icon"
                                        />
                                    </AvatarIcon>
                                </ServiceLeft>
                                <ServiceRight>
                                    <Heading>Export</Heading>
                                    <SubHeading>
                                        We're dedicated to sharing the flavors of our products with
                                        the world. Through our commitment to quality and innovation
                                    </SubHeading>
                                </ServiceRight>
                            </ServiceBox>
                            <ServiceBox>
                                <ServiceLeft>
                                    <AvatarIcon>
                                        <Image
                                            src={require("../assets/images/truck.svg").default}
                                            alt="icon"
                                        />
                                    </AvatarIcon>
                                </ServiceLeft>
                                <ServiceRight>
                                    <Heading>Distribution</Heading>
                                    <SubHeading>
                                        Our efficient distribution network ensures that our products
                                        reach every corner with ease. With a organized system and
                                        dedication to timely deliveries
                                    </SubHeading>
                                </ServiceRight>
                            </ServiceBox>
                            <ServiceBox>
                                <ServiceLeft>
                                    <AvatarIcon>
                                        <Image
                                            src={require("../assets/images/factory.svg").default}
                                            alt="icon"
                                        />
                                    </AvatarIcon>
                                </ServiceLeft>
                                <ServiceRight>
                                    <Heading>Manufacturing</Heading>
                                    <SubHeading>
                                        Crafted with precision and care, our manufacturing process
                                        ensures the highest quality products that meet and exceed
                                        industry standards
                                    </SubHeading>
                                </ServiceRight>
                            </ServiceBox>
                            <ServiceBox>
                                <ServiceLeft>
                                    <AvatarIcon>
                                        <Image
                                            src={
                                                require("../assets/images/supermarket.svg").default
                                            }
                                            alt="icon"
                                        />
                                    </AvatarIcon>
                                </ServiceLeft>
                                <ServiceRight>
                                    <Heading>Retail</Heading>
                                    <SubHeading>
                                        Our retail experience brings the charm of our bakery
                                        directly to your fingertips. Discover the joy of quality and
                                        flavor with every visit
                                    </SubHeading>
                                </ServiceRight>
                            </ServiceBox>
                        </ServiceCard>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Services;

const MainContainer = styled.div`
    padding: 100px 0px;
    background: #f7f2e8;
    @media all and (max-width: 980px) {
        padding: 70px 0px;
    }
    @media all and (max-width: 640px) {
        padding: 50px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    position: relative;
`;
const ContentBox = styled.div`
    margin-bottom: 80px;
    @media all and (max-width: 480px) {
        margin-bottom: 50px;
    }
`;
const MainHeading = styled.div`
    font-family: "poppins_semibold";
    font-size: 34px;
    text-align: center;
    margin-bottom: 15px;
    span {
        color: #d0741d;
    }
    @media all and (max-width: 360px) {
        font-size: 32px;
    }
`;
const Para = styled.p`
    width: 70%;
    margin: 0 auto;
    font-size: 19px;
    text-align: center;
    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 769px) {
        width: 100%;
        font-size: 17px;
    }
    @media all and (max-width: 769px) {
        font-size: 16px;
    }
`;
const ServiceCard = styled.div`
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .hover-effect {
        transition: transform 0.2s ease-in-out;
    }

    .hover-effect:hover {
        transform: scale(1.05);
    }
`;
const ServiceBox = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 44%;
    min-height: 200px;
    background-color: #ffff;
    border-radius: 20px;
    box-shadow: 13px 4px 64px rgba(0, 0, 0, 0.08);
    @media all and (max-width: 1200px) {
        width: 47%;
    }
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto;
        min-height: 180px;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
    }
`;
const AvatarIcon = styled.div``;
const Image = styled.img`
    height: 100%;
    width: 100%;
    display: block;
`;
const Heading = styled.h3`
    color: #1f3347;
    font-family: "poppins_semibold";
    text-align: left;
    margin-bottom: 15px;
    font-size: 22px;
    @media all and (max-width: 768px) {
        font-size: 20px;
    }
`;
const SubHeading = styled.p`
    text-align: left;
    font-size: 15px;
`;

const ServiceLeft = styled.div`
    width: 12%;
    @media all and (max-width: 480px) {
        width: 15%;
    }
`;
const ServiceRight = styled.div`
    width: 80%;
`;
