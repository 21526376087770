import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../../includes/Header";
import HomeLandingPage from "../../screens/HomeLandingPage";
import Footer from "../../includes/Footer";

function AppRouter() {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<HomeLandingPage />} />
            </Routes>
            <Footer  />
        </>
    );
}

export default AppRouter;
