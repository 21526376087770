import React from "react";
import Spotlight from "./Spotlight";
import About from "./About";
import TopProducts from "./TopProducts";
import Services from "./Services";
import Locations from "./Locations";
import Distributor from "./Distributor";
import ContactUs from "./ContactUs";

function HomeLandingPage() {
    return (
        <>
            <Spotlight />
            <About />
            <TopProducts />
            <Services />
            <Locations />
            <Distributor />
            <ContactUs />
        </>
    );
}

export default HomeLandingPage;
